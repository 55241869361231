<template>
    <div class="p-mobile-navigation development" ref="$mobileNav">
        <div class="shape">
            <div class="round top"></div>
        </div>
        <div class="menu">
            <nav class="c-navigation" ref="$nav">
                <ul class="parents">
                    <li
                        v-for="item in menus__.menu_general"
                        :key="item.id"
                        class="item"
                        :data-section="item.customAttributes[0].value"
                    >
                        <button
                            v-if="item.typeLabel == 'Passive'"
                            type="button"
                            class="item-link"
                            @click="scrollToSection(item.customAttributes[0].value)"
                        >
                            <span class="text">{{ item.title }}</span>
                        </button>

                        <NuxtLink
                            v-else-if="item.typeLabel == 'Entry'"
                            :to="useConvertedLink(item.element, item)"
                            class="item-link"
                        >
                            <span class="text">{{ item.title }}</span>
                        </NuxtLink>
                    </li>
                    <li>
                        <NuxtLink to="mailto:info@ponderadevelopment.com" title="Partner with us" class="btn-partner"
                            >Partner with us</NuxtLink
                        >
                    </li>
                </ul>
            </nav>
        </div>
        <div class="contact">
            <div class="links">
                <NuxtLink :to="settings__.linkedin" target="_blank" rel="noopener" class="linked-in-link">
                    <span class="icon"><IconsLinkedin :fill="'var(--p-dark)'" /></span>
                    <span class="text">{{ "LinkedIn" }}</span>
                </NuxtLink>
                <NuxtLink :to="`tel:` + settings__.phone[0]?.linkUrl" :title="settings__.phone[0]?.linkText">{{
                    settings__.phone[0]?.linkText
                }}</NuxtLink>
                <NuxtLink :to="`mailto:` + settings__.emailDevelopment" :title="settings__.emailDevelopment">{{
                    settings__.emailDevelopment
                }}</NuxtLink>
            </div>
        </div>
    </div>
</template>

<script setup>
    const route = useRoute();
    const store = useGlobalStore();

    const { settings, menus, languageCode } = storeToRefs(store);
    const { $gsap: gsap } = useNuxtApp();

    const settings__ = computed(() => settings.value[languageCode.value][0]);
    const menus__ = computed(() => menus.value[languageCode.value]);

    const $nav = ref(null);
    const ctx = ref(null);

    const scrollToSection = async (el) => {
        if (route.name == "index___en") {
            const section = document.querySelector(`.${el}`);
            if (section) {
                store.setToggleMobileMenu(false);
                gsap.to(window, {
                    duration: 1.5,
                    ease: "power4.easeIn",
                    scrollTo: {
                        y: section,
                        offsetY: 120
                    }
                });
            }
        } else {
            await navigateTo({
                path: "/",
                query: {
                    section: el
                }
            });
        }
    };

    watch(
        () => store.pageTransitionComplete,
        (state) => {
            if (state) {
                if (route.query?.section) {
                    const hashSection = route.query.section;
                    window.history.replaceState({}, "", route.path);
                    setTimeout(() => {
                         scrollToSection(hashSection);
                    }, 300);
                }

                nextTick(() => (ctx.value = useCtx(useHeaderMenuAnimation($nav.value), $nav.value)));
            }
        }
    );

    onBeforeUnmount(() => {
        if (ctx.value) ctx.value.revert();
    });
</script>
