<template>
    <div id="pondera-main" class="pondera-main">
        <CommonPageLoader />
        <CommonHeaderDevelopment />
        <NavigationMobileDevelopment />
        <div class="lenis-wrapper">
            <main class="lenis-content is-global-loading">
                <div class="lenis-slot">
                    <slot />
                </div>
                <CommonFooterDevelopment />
            </main>
        </div>
    </div>
</template>

<script setup>
    const { $ScrollTrigger: ScrollTrigger } = useNuxtApp();
    const isDesktop = useMediaQuery("(min-width: 1181px)");
 
    watch(
        () => isDesktop.value,
        () => nextTick(() => ScrollTrigger.refresh(true))
    );
</script>
