<template>
    <header id="p-header" class="p-header development">
        <div class="c-container">
            <div class="p-header--grid">
                <div class="c-logo">
                    <NuxtLink :to="'/'" class="link-home">
                        <IconsDlogo />
                    </NuxtLink>
                </div>
                <nav class="c-navigation" ref="$nav">
                    <ul class="parents">
                        <li
                            v-for="item in menus__.menu_general"
                            :key="item.id"
                            class="item"
                            :data-section="item.customAttributes[0].value"
                        >
                            <button
                                v-if="item.typeLabel == 'Passive'"
                                type="button"
                                class="item-link"
                                @click="scrollToSection(item.customAttributes[0].value)"
                            >
                                <span class="text">{{ item.title }}</span>
                            </button>

                            <NuxtLink
                                v-else-if="item.typeLabel == 'Entry'"
                                :to="useConvertedLink(item.element, item)"
                                class="item-link"
                            >
                                <span class="text">{{ item.title }}</span>
                            </NuxtLink>
                        </li>
                    </ul>
                </nav>
                <div class="c-custom">
                    <NuxtLink
                        v-show="isDesktop"
                        to="mailto:info@ponderadevelopment.com"
                        title="Partner with us"
                        class="btn-partner"
                        >Partner with us</NuxtLink
                    >
                    <UiHamburger v-show="!isDesktop" />
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
    const { $gsap: gsap } = useNuxtApp();
    const store = useGlobalStore();
    const route = useRoute();
    const { menus, languageCode } = storeToRefs(store);

    const isDesktop = useMediaQuery("(min-width: 1181px)");
    const menus__ = computed(() => menus.value[languageCode.value]);

    const $nav = ref(null);
    const ctx = ref(null);

    const scrollToSection = async (el) => {
        if (route.name == "index___en") {
            const section = document.querySelector(`.${el}`);
            if (section) {
                gsap.to(window, {
                    duration: 1.5,
                    ease: "power4.easeIn",
                    scrollTo: {
                        y: section,
                        offsetY: 120
                    }
                });
            }
        } else {
            await navigateTo({
                path: "/",
                query: {
                    section: el
                }
            });
        }
    };

    watch(
        () => store.pageTransitionComplete,
        (state) => {
            if (state) {
                if (route.query?.section) {
                    const hashSection = route.query.section;
                    window.history.replaceState({}, "", route.path);
                    nextTick(() => scrollToSection(hashSection));
                }

                nextTick(() => (ctx.value = useCtx(useHeaderMenuAnimation($nav.value), $nav.value)));
            }
        }
    );

    onBeforeUnmount(() => {
        if (ctx.value) ctx.value.revert();
    });
</script>
