const setActive = (navLinks, link) => {
    navLinks.forEach((item) => item.classList.remove("active"));
    link.classList.add("active");
}

export function useHeaderMenuAnimation(el) {
    const { $ScrollTrigger: ScrollTrigger } = useNuxtApp();
    const navLinks = el.querySelectorAll('ul li');

    if (navLinks) {
        navLinks.forEach((item) => item.classList.remove("active"));

        navLinks.forEach(link => {
            const group = document.querySelector(`.${link.dataset.section}`);
            if (group) {
                ScrollTrigger.create({
                    trigger: group,
                    invalidateOnRefresh: true,
                    start: "top center",
                    end: "bottom center",
                    onToggle: (self) => self.isActive && setActive(navLinks, link)
                });
            }
        });
    }
}
