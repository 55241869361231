<template>
    <footer id="p-footer" class="p-footer bg-dark">
        <div class="footer-for-development">
            <div class="top-part">
                <div class="c-grid">
                    <div class="cell sm-12 md-o-1 md-5">
                        <div class="f-label">{{ "Office" }}</div>
                        <div v-if="settings__.offices.length" class="f-office">
                            <div
                                class="office"
                                v-for="(office, index) in settings__.offices.filter((item) => item.main == '1')"
                                :key="index"
                            >
                                <address class="address" v-html="nl2br(office.address)"></address>
                            </div>
                        </div>
                    </div>
                    <div class="cell sm-12 md-5">
                        <div class="f-label">{{ "Contact" }}</div>
                        <div class="f-links">
                            <a
                                :href="`tel:` + settings__.phoneDevelopment[0]?.linkUrl"
                                :title="settings__.phoneDevelopment[0]?.linkText"
                                class="f-link"
                                >{{ settings__.phoneDevelopment[0]?.linkText }}</a
                            >
                            <a
                                :href="`mailto:` + settings__.emailDevelopment"
                                :title="settings__.emailDevelopment"
                                class="f-link"
                                >{{ settings__.emailDevelopment }}</a
                            >

                            <NuxtLink
                                :to="settings__.linkedinDevelopment"
                                target="_blank"
                                rel="noopener"
                                class="linked-in-link m-y-t-20"
                            >
                                <span class="icon"><IconsLinkedin /></span>
                                <span class="text">{{ "LinkedIn" }}</span>
                            </NuxtLink>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="middle-part">
                <div class="c-grid">
                    <div class="cell sm-12 md-o-6 md-5">
                        <div class="f-label">{{ "Newsletter" }}</div>
                        <FormsNewsletter />
                    </div>
                </div>
            </div> -->
            <div class="bottom-part">
                <div class="c-grid">
                    <div class="cell sm-12 md-o-1 md-10">
                        <NuxtLink :to="localePath({ name: 'index' })" class="f-logo">
                            <IconsSmallLogoDevelopment/>
                        </NuxtLink>
                        <div class="p-copyright-nav">
                            <div class="copyright" v-html="convertStrYear(settings__.copyrightDevelopment)"></div>
                            <div class="menu">
                                <nav class="nav">
                                    <ul>
                                        <li v-for="(item, index) in menus__.menu_links" :key="index">
                                            <NuxtLink :to="useConvertedLink(item.element, item)" class="item-link">
                                                <span class="text">{{ item.title }}</span>
                                            </NuxtLink>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <!-- <div class="about">Part of Pondera Group</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
    const localePath = useLocalePath();
    const store = useGlobalStore();
    const { menus, settings, languageCode } = storeToRefs(store);

    const settings__ = computed(() => settings.value[languageCode.value][0]);
    const menus__ = computed(() => menus.value[languageCode.value]);
</script>
